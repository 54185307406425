import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";
import OGP from "../images/ogp.jpg";

function SEO({ description, lang, meta, title, slug, useTitleTemplate }) {
    const { site } = useStaticQuery(
        graphql`
            query {
                site {
                    siteMetadata {
                        title
                        author
                        siteUrl
                        description
                    }
                }
            }
        `
    );

    const formattedTitle = useTitleTemplate
        ? `${title} | ${site.siteMetadata.title}`
        : title;
    const metaDescription = description
        ? description
        : site.siteMetadata.description;
    const ogImageUrl = slug
        ? `https://r2-public-worker.quipped.workers.dev/${slug}.png`
        : site.siteMetadata.siteUrl + OGP;

    return (
        <Helmet
            htmlAttributes={{
                lang,
            }}
            title={formattedTitle}
            meta={[
                {
                    name: `title`,
                    content: formattedTitle,
                },
                {
                    name: `description`,
                    content: metaDescription,
                },
                {
                    property: `og:title`,
                    content: formattedTitle,
                },
                {
                    property: `og:description`,
                    content: metaDescription,
                },
                {
                    property: `og:type`,
                    content: `website`,
                },
                {
                    property: `og:image`,
                    content: ogImageUrl,
                },
                {
                    name: `twitter:card`,
                    content: `summary_large_image`,
                },
                {
                    name: `twitter:creator`,
                    content: site.siteMetadata.author,
                },
                {
                    name: `twitter:title`,
                    content: formattedTitle,
                },
                {
                    name: `twitter:description`,
                    content: metaDescription,
                },
                {
                    name: `twitter:image`,
                    content: ogImageUrl,
                },
            ].concat(meta)}
        />
    );
}
SEO.defaultProps = {
    lang: `ja`,
    meta: [],
    description: ``,
    useTitleTemplate: true,
};
SEO.propTypes = {
    description: PropTypes.string,
    lang: PropTypes.string,
    meta: PropTypes.arrayOf(PropTypes.object),
    title: PropTypes.string.isRequired,
    useTitleTemplate: PropTypes.bool,
};
export default SEO;
